const dictionary = {
  localizations: {
    buttons: {
      uploadFile: 'Upload File',
      approve: 'Genehmigen',
      adRequest: 'Ad Request',
      createAdRequest: 'Create Ad Request',
      login: 'Anmelden',
      rememberMe: 'Erinnere dich an mich',
      save: 'Speichern',
      clear: 'Klar',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      cancel: 'Stornieren',
      yes: 'Ja',
      no: 'Nein',
      update: 'Update',
      send: 'Einreichen',
      createNewRelation: 'Create New Relation',
      makePlan: 'Plan',
      selectAll: 'Select All',
      true: 'True',
      false: 'False',
      makePeriodicPlan: 'Periodic Plan',
      makeSpecialPlan: 'Special Plan',
      filter: 'Filter',
      detail: 'Detail',
      hourBasedRelease: 'Release Hour Based',
      minuteBasedRelease: 'Release Minute Based',
      songBasedRelease: 'Release Song Based',
      logout: 'Logout',
      hourBased: 'Hour Based',
      minuteBased: 'Minute Based',
      songBased: 'Song Based',
      home: 'Home',
      dashboard: 'Dashboard',
      dashboardPartner: 'What Happened? What will happen?',

      userManagement: 'User Management',
      createUser: 'Create User',
      users: 'Users',
      firms: 'Firms',
      sectorOfFirm: 'Sector Of Firm',
      branches: 'Branches',
      statistic: 'Statistic',
      onOf: 'Online/Offline',
      songs: 'Songs',
      fieldsForSong: 'Fields For Song',
      songCategory: 'Song Category',
      artistName: 'Artist Name',
      productionCompany: 'Production Company',
      legalTracking: 'Legal Tracking',
      melodist: 'Melodist',
      writer: 'Writer',
      album: 'Album',
      songPool: 'Song Pool',
      songPoolPartnerRelation: 'Pool-Partner Relation',
      addNewSong: 'Add New Song',
      allSongs: 'All Songs',
      addSongAndViewPlaylist: 'Add Song to Playlist And View',
      playlist: 'Playlist',
      createPlaylist: 'Create Playlist',
      advertisement: 'Advertisement',
      createAdvertisementSet: 'Create Advertisement Set',
      advertisementPool: 'Advertisement Pool',
      standardAdvertisement: 'Standard Advertisement',
      interAdvertisement: 'Inter Advertisement',
      specialAdvertisement: 'Special Advertisement',
      soundTypes: 'Sound Types',
      createSoundType: 'Create Sound Type',
      onOff: 'Online/Offline',
      addPlaylistSong: 'Add Playlist Song',
      add: 'Hinzufügen',
      addChosenSongsToPlaylist: 'Add Chosen Songs To Playlist',
      createPlaylistPublish: 'Playlist Released',
      confirm: 'Confirm',
      help: 'Help',
      iUnderstand: 'I Understand !',
      release: 'Release',
      unRelease: 'UnRelease',
      timeline: ' Timeline',
      sounds: ' Sounds',
      advertisements: ' Advertisements',
      periodicAdvertisements: 'Periodic Advertisements',
      specialAdvertisements: 'Special Advertisements',
      cancelOption: 'Cancel Option',
      seeBranches: 'See Branches',
      addToSongPlaylist: 'Add To Song Playlist',
      createPlaylistName: 'Create Playlist Name',
      createPlaylists: 'Create Your Playlist',
      createAdsSet: 'Advertisement Set',
      advertisementPlanning: 'Plan Advertisement ',
      standardAdsPublish: 'Publish Standard Ad',
      interAdsPublish: 'Publish Inter Ad',
      specialAdsPublish: 'Publish Special Ad',
      periodicAdvertisementsRelease:'Publish Periodic Ad',
      publishSound: 'Publish Jingle',
      playlists: 'Playlist',
      addJingle: 'Add New Jingle',
      _button: 'Stornieren',
    },
    columns: {
      createdAt: 'Creation Date',
      updatedAt: 'Updated On',
      woman: 'Female',
      man: 'Male',
      other: 'Other',
      branch: 'Branch',
      partner: 'Partner',
      guest: 'Guest',
      manager: 'Manager',
      admin: 'Admin',
      inter: 'Inter',
      standard: 'Standard',
      special: 'Special',
      advertisementName: 'Advertisement Name',
      time: 'Time',
      status: 'Status',
      isReleased: 'Is Released',
      planned: 'Planned',
      unPlanned: 'Unplanned',
      released: 'Defined',
      notReleased: 'Undefined',
      branches: 'Branches',
      totalTime: 'Total Time',
      type: 'Type',
      name: 'Name',
      duration: 'Duration',
      soundTypes: 'Sound Types',
      jingle: 'Jingle',
      jinglePools: 'Jingle Pools',
      jinglePublish: 'Jingle Publish',

      announce: 'Announce',
      teaser: 'Teaser',
      displayTime: 'Sendezeit',
      true: 'Wahr',
      false: 'falsch',
      hour: 'Stunde',
      song: 'Lied',
      minute: 'Minuten',
      soundTypesName: 'Sound Type Name',
      playlist: 'Playlist',
      selectedDays: 'Selected Days',
      startHour: 'Start Hour',
      endHour: 'End Hour',
      date: 'Date',
      startDate: 'Start Date',
      endDate: 'End Date',
      onlineCount: 'Online Count',
      offlineCount: 'Offline Count',
      partnerBranch: 'Partner Branch',
      lastOnline: 'Last Onlie',
      lastOffline: 'Last Offline',
      version: 'Version',
      createdBy: 'Created By',
      partners: 'Partners',
      jinglePoolName: 'Jingle Pool Name',
      jingleName: 'Jingle Name',

      _columns: 'columns',
    },
    labels: {
      male: 'Male',
      female: 'Female',
      forgotMyPassword: 'Ich habe mein Passwort vergessen',
      forgotPasswordMessage:
        'Wenn sie ihr passwort vergessen haben, können sie es hier erneuern.',
      partnerBranch: 'Partner Branch',
      advertisementType: 'Advertisement Type',
      isCutSong: 'Is Cut Song',
      advertisementSet: 'Advertisement Set',
      startDate: 'Start Date',
      endDate: 'End Date',
      startDateWithTime: 'Start Date - Time',
      endDateWithTime: 'End Date - Time',
      time: 'Time',
      true: 'True',
      false: 'False',
      advertisementName: 'Advertisement Name',
      partner: 'Partner',
      start: 'Start',
      end: 'End',
      duration: 'Duration',
      dailyRepeat: 'Daily Repeat',
      totalRepeat: 'Total Repeat',
      dailyTotalDuration: 'Daily Total Duration',
      totalDuration: 'Total Duration',
      broadcastTimes: 'Broadcast Times',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
      name: 'Name',
      soundType: 'Sound Type',
      advertisemet: 'Anzeige',
      repeatNumber: 'Repeat Number',
      period: 'Period',
      onHowManyMinutes: 'How many minutes on',
      onHowManyHours: 'How many hours on',
      onHowManySongs: 'How many songs on',
      album: 'Album',
      artist: 'Künstler',
      melodist: 'Komponist',
      writer: 'Song Writer',
      productionCompany: 'Produktionsunternehmen',
      playlistKind: 'Playlist Genre',
      popularity: 'Song Quality',
      soundName: 'Sound Name',
      repeat: 'Wiederholen',
      periodParameter: 'Zeitraum Parameter',
      selectPlaylistSongs:
        'Wählen Sie eine Wiedergabeliste aus, um ihre Songs anzuzeigen:',
      newSelectSongWhichLsit:
        'Welche wiedergabeliste möchten sie gerade ausgewählte Songs hinzufügen?',
      jan: 'Januar',
      feb: 'Februar',
      march: 'März',
      apr: 'April',
      may: 'Mai',
      june: 'Juni',
      jul: 'Juli',
      aug: 'August',
      sep: 'September',
      oct: 'Oktober',
      nov: 'November',
      dec: 'Dezember',
      startTime: 'Start Time',
      endTime: 'End Time',
      allBranchesSelected: 'All Branches Selected',
      signboard: 'Signboard Name',
      jingle: 'Jingle',
      announce: 'Announce',
      teaser: 'Teaser',
      inter: 'Inter',
      standard: 'Standard',
      special: 'Special',
      songs: 'Songs',
      advertisements: 'Advertisements',
      soundTypes: 'Sound Types',
      totalPublicationsCount: 'Total Publications',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
      days: 'Days',
      allDays: 'All Days',
      weekend: 'Weekend',
      weekdays: 'Weekdays',
      startHour: 'Start Hour',
      endHour: 'End Hour',
      song: 'Song',
      songPool: 'Song Pool',
      wasDefined: 'was defined',
      affectedBranches: 'Affected branches',
      catagory: 'Category',
      dateTooltip: 'You can see the songs identified by choosing a date.',
      inHowManySongs: 'In How Many Songs',
      _label: 'label',
    },
    placeholders: {
      multipleLinksTypo: 'Separate multiple links with newlines.',
      password: 'Kennwort',
      username: 'Benutzername',
      chooserole: 'Rolle Wählen',
      search: 'Wort zu suchen...',
      selectValue: 'Select a value please',
      selectPartner: 'Select a partner please',
      selectBranch: 'Select a branch please',
      selectPlaylist: 'Please select a playlist',
      selectAdvertisementSet: 'Select an advertisement set please',
      selectTime: 'Select a time please',
      selectDate: 'Select date',
      days: 'Select day',
      selectJingle: 'Select a jingle pool',
      _placeholder: 'placeholder',
    },
    messages: {
      requiredUsername: 'Geben Sie Ihren Benutzernamen ein!',
      requiredPassword: 'Geben Sie Ihr Passwort ein!',
      successMessage: 'Registrierung erfolgreich.',
      successUserCreate: 'Der Benutzer wurde erfolgreich erstellt',
      firstname: 'Bitte name eingeben!',
      lastname: 'Bitte nachname eingeben!',
      email: 'Bitte e-mail eingeben!',
      password: 'Bitte passwort eingeben!',
      confirmpassword: 'Bitte passwort bestätigen!',
      notvalidemail: 'Die Eingabe ist ungültig E-Mail!',
      recordNotFound: 'Kein verwandter datensatz gefunden!',
      somethingwrong: 'Etwas ist schief gelaufen!',
      successUpdate: 'Das update ist erfolgreich.',
      somethingBad: 'Etwas ist schlecht gelaufen!',
      sure: 'Bist du sicher?',
      chooseUser: 'Please choose user',
      notFoundContent: 'There are not data related with your query',
      notAuthorized: 'Sorry, you are not authorized to access this page.',
      serverWrong: 'Sorry, the server is wrong.',
      notExist: 'Sorry, the page you visited does not exist.',
      successful: 'Successful',
      selectProductionCompany: 'Bitte wählen sie die produktionsfirma aus.',
      selectArtist: 'Bitte wählen sie einen künstler.',
      selectAlbum: 'Bitte wählen sie ein album aus.',
      selectWriter: 'Bitte wählen sie den songwriter.',
      selectMelodist: 'Bitte wählen sie einen komponisten.',
      selectSongPool: 'Bitte wählen Sie einen songpool.',
      selectSongCategory: 'Bitte wählen sie eine songkategorie.',
      selectPartner: 'Bitte wählen sie eine firma aus.',
      clickUpload: 'Laden Sie einen Song hoch',
      invalideFile: 'Ungültiger dateityp',
      fileSmaller: 'Die Datei muss kleiner als 100 MB sein',
      clickUploadImage: 'Click Upload Image',
      selectPopularity: 'Bitte wählen sie eine beliebtheit.',
      notSuccessUserCreate: 'Fehler beim erstellen des benutzers.',
      forgotMessage:
        'Ihr passwort wurde an Ihre email gesendet.Bitte überprüfen sie.',
      selectPlaylistKind: 'Bitte wählen Sie den Typ der Wiedergabeliste',
      primaryPhone: 'Bitte geben sie ihre telefonnummer ein!',
      secondaryPhone: 'Bitte geben Sie Ihre handynummer ein!',
      selectPartnerSector: 'Wählen Sie den unternehmensbereich',
      notFountContent: 'Inhalt nicht gefunden.',
      notaddSong:
        'Dem tschetschenischen Songpool wurden noch keine Songs hinzugefügt.',
      fileSize: 'Die Dateigröße darf nicht größer als 15 Sekunden sein.',
      uSelectedDate: 'You selected date',
      datesGapCanNotBeGt90Days: 'Dates gap can not be greater than 90 days!',
      clickUploadJingle: 'Click Upload Jingle',
      appHasNewVersion: 'App Has New Version',
      _messages: 'messages',
    },
    form: {
      createuser: 'Benutzer Erstellen',
      username: 'Nutzername',
      name: 'Name',
      surname: 'Nchname',
      email: 'Email',
      password: 'Passwort',
      confirmpassword: 'Bestätige das Passwort',
      description: 'Beschreibung',
      role: 'Rolle',
      _form: 'form',
    },
    titles: {
      voiceoverPerformer: 'Voice-over Performer',
      links: 'Links',
      text: 'Text',
      title: 'Title',
      adRequest: 'Ad Request',
      username: 'Nutzername',
      name: 'Name',
      surname: 'Nchname',
      email: 'Email',
      role: 'Rolle',
      partnerName: 'Firmenname',
      partnerBranchName: 'Filialname',
      action: 'Aktion',
      users: 'Benutzer',
      userDetail: 'Benutzerdetails',
      description: 'Beschreibung',
      newPartner: 'Neue Firma Erstellen',
      newPartnerBranch: 'Neuen Zweig Erstellen',
      songCategory: 'Song Kategorie',
      songPoolCategory: 'Song Pool Kategorie',
      categoryName: 'Kategoriename',
      time: 'Zeit',
      totalFiles: 'Anzahl der Dateien',
      newCategoryAdd: 'Neue Kategorie',
      newSongPoolAdd: 'Neue Songkategorie Erstellen',
      artistFirstName: 'Künstlername',
      artistLastName: 'Nachname des Künstlers',
      gender: 'Geschlecht',
      Artist: 'Künstler',
      newArtist: 'Neuer Künstler',
      productionCompanyName: 'Produkt Firmenname',
      productionCompany: 'Produktfirma',
      newproductionCompany: 'Neuer Product Company',
      advertisementName: 'Rechtliche Verfolgung',
      advertisementType: 'Art der Anzeige',
      advertisementItems: 'Zeit',
      advertisementSet: 'Werbeset',
      newAdvertisementSet: 'Neues Werbeset',
      legalPercuitName: 'Legal Percuit Name',
      legalPercuit: 'Legal Percuit',
      newlegalPercuit: 'Neuer Legal Percuit',
      choose: 'Wählen',
      country: 'Land',
      productionDate: 'Produktions Datum',
      album: 'Albüm',
      songName: 'Liedtitel',
      songWriter: 'Songwriter',
      composer: 'Komponist',
      subCategory: 'Unterkategorie',
      melodists: 'Melodist',
      newMelodist: 'New Melodist',
      albums: 'Albums',
      newAlbum: 'Neuer Album',
      writers: 'Songschreiber',
      newWriter: 'Neuer Songwriter',
      popularity: 'Unterkategorie',
      songPool: 'Song Pool',
      song: 'Song',
      file: 'File',
      photo: 'Photo',
      planAdvertisement: 'Advertisement Plan',
      standardAds: 'Standard Advertisement',
      interAds: 'Inter Advertisement',
      createSoundType: 'Create Sound Type',
      userInfo: 'User Info',
      newAdvertisement: 'New Advertisement',
      releaseSound: 'Release Sound',
      newPlaylist: 'New Playlist',
      playlist: 'Playlist Kind',
      backHome: 'Back Home',
      primaryPhone: 'Telephone',
      secondaryPhone: 'GSM',
      partnerSector: 'Unternehmenssektor',
      sectorName: 'Sektorname',
      newSector: 'Neuer Sektor',
      sectorCategory: 'Sektorkategorie',
      newSectorCategory: 'Neue Sektorkategorie',
      songPoolName: 'Song Pool Name',
      createNewRelation: 'Neue Beziehung erstellens',
      addSongtoPlaylist: 'Songs zur Wiedergabeliste Hinzufügen',
      activeBranchCount: 'Active Branch Count',
      totalPartnerCount: 'Total Partner Count',
      totalBranchCount: 'Total Branch Count',
      planningPlaylistCount: 'Planning Playlist Count',
      planningSpeciesCount: 'Planning Species Count',
      planningTeaserCount: 'Planning Teaser Count',
      planningAdvertisementCount: 'Planning Advertisement Count',
      totalSongCount: 'Total Song Count',
      totalCategoryCount: 'Total Category Count',
      statisticSummary: 'Zusammenfassende Daten Dieses Monats',
      advertisementStatistic: 'Anzeigen Statistik',
      soundTypeStatistic: 'Statistik der Sprachtypen',
      songStatistic: 'Song Statistik',
      mountlyAdvertisementStatistic: 'Werbestatistik Nach Monat',
      adsvertisementPercent: 'Prozentsatz der Anzeigen',
      teaserMountlyStatistic: 'Statistik der Werbeaktionen nach Monat',
      jingleMounlyStatistic: 'Statistik der Jingles nach Monaten',
      announceMontlyStatictic: 'Ankündigungs Statistik nach Monat',
      month: 'Monat',
      most: 'Die meisten',
      least: 'Am Wenigsten',
      playTenSong: '10 Songs Spielen',
      beforeMonth: 'Im Vergangenen Monat',
      afterMonth: 'Nächsten Monat',
      mostThreeSong: 'Top 3 Songs Spielen:',
      advertisementReleasedCount: 'Anzahl der Anzeigenveröffentlichungen:',
      songBaseReleasedCount: 'Veröffentlichungsnummern der Songtypen:',
      totalReleased: 'Insgesamt Freigegeben:',
      timelineTable: 'Timeline Daily Detail Table',
      verticalTimeline: 'Detail Timeline',
      tr: 'Turkisch',
      en: 'Englisch',
      de: 'Deutsche',
      welcome: ' Havalı Ajans Player Management Panel',
      howToReleaseSoundType: 'How to release a sound type?',
      songDetail: 'Song Detail',
      standardAdvertisement: 'Standard Advertisement',
      advertisementDetail: 'Advertisement Detail',
      soundDetail: 'Sound Detail',
      specialAdvertisement: 'Special Advertisement',
      announce: 'Announce',
      jingle: 'Jingle',
      teaser: 'Teaser',
      timeline: 'Timeline',
      interAdvertisement: 'Inter Advertisement',
      publishList: 'Publish List',
      standartStartDate: 'Start',
      standartEndDate: 'End',
      advertisement: 'Advertisement',
      updatePlaylist: 'Update Playlist',
      newJingle: 'New Jingle',
      jingleCount: 'Jingle Count',
      newJinglePoolName: 'New Jingle Pool Name',
      _titles: 'titles',
    },
    header: {
      home: 'Zuhause',
      dashboard: 'Instrumententafel',
      usermanagement: 'Benutzerverwaltung',
      createuser: 'Benutzer Erstellen',
      partner: 'Unternehmen',
      partnerBranch: 'Zweig',
      statistic: 'statistisch',
      onlineoffline: 'Online/Offline',
      playlistmanager: 'Playlist-Verwaltung',
      adsmanager: 'Anzeigenverwaltung',
      standartads: 'Standardwerbung',
      interads: 'Zwischenwerbung',
      specialads: 'Sonderwerbung',
      kindmanager: 'Artenmanagement',
      songPoolPartnerRelation: 'Song Pool and Partner Relation',
      songUpload: 'Song Upload',
      soundTypes: 'Sound Types',
      advertisementPool: 'Advertisement Pool',
      idendifiedSoundKinds: 'Sound Type Defined',
      playlist: 'Playlist Released',
      onlineOfflineInfo: 'Online Offline Status',
      totalPartnerBranch: 'Total Partner Branch',
      online: 'Online',
      offline: 'Offline',
      totalBranch: 'Total Partner Status',
      releasedJingle: 'Released Jingle',
      _header: 'header',
    },
    warnings: {
      required: 'Dieses feld wird benötigt.',
      email: 'Der wert ist keine gültige E-Mail.',
      string: 'Dieses feld muss Text sein',
      min: 'Der wert liegt unter der mindestgrenze',
      max: 'Der wert ist größer als die höchstgrenze',
      noData: 'Keine Daten',
      noAdvertisement: 'No Advertisement',
      notYetAddedSongPoolToChosenPlaylist:
        'Songs was not added on chosen playlist yet!',
      allBranchesSelected: 'All playlists was chosen!',
      chosenPlaylistAffectAllBranches:
        'Chosen playlist will affect all branches!',
      startMustBeSmallerThanEnd: 'Start value must be smaller than end value!',
      chooseOneValue: 'Sie müssen einen von mindestens zwei Werten wählen!',
      periodicAds:'Periodic können in den angegebenen Datumsbereichen nicht gefunden werden.',
      standartAds:
        'Standardanzeigen können in den angegebenen Datumsbereichen nicht gefunden werden.',
      interAds:
        'In den angegebenen Datumsbereichen können keine Suchanzeigen gefunden werden.',
      specialAds:
        'Sonderanzeigen können in den angegebenen Datumsbereichen nicht gefunden werden.',
      noApp: 'Kurulum Yapılmadı',
      chooseSongPool: 'Choose a song pool please!',
      _warning: 'warning',
    },
    tooltips: {
      hourBased: 'Play every X hours',
      minuteBased: 'Play every X minutes',
      songBased: 'Play one in X songs',
    },
    errors: {
      audioTooLong: 'Audio file lenght can not be greater than 5 minutes!',
      requestFailed: 'Entschuldigung, anfrage fehlgeschlagen!',
      pleaseCheckInputs: 'Bitte überprüfen sie die eingabewerte',
      uniqueConstraint: 'Dieser datensatz wurde bereits zuvor aufgenommen.',
      checkUsernamePassword:
        'Bitte überprüfen Sie Ihren Benutzernamen und Ihr Passwort',
      unsupportedMimeType: 'Lütfen yüklediğiniz dosya tipini kontrol ediniz.',
      timelineConflict: 'Timeline Conflict',
      unSuccessful: 'Unsuccessful',
      _error: 'error',
      any: {
        required: 'Ein erforderlicher wert war nicht vorhanden.',
        _any: 'any',
      },
      string: {
        base: 'Der wert stimmte nicht mit dem regulären ausdruck überein.',
        empty: 'Der wert ist leer.',
        min: 'Der wert ist kürzer als erwartet.',
        max: 'Der wert ist länger als erwartet.',
        email: 'Der wert ist keine gültige E-Mail.',
        lowercase: 'Der wert ist nicht nur in kleinbuchstaben angegeben.',
        uppercase: 'Der wert ist nicht nur in großbuchstaben geschrieben.',
        length: 'Die zeichenfolge hat nicht die erwartete Länge.',
        _string: 'srting',
        pattern: {
          base: 'Der wert stimmte nicht mit dem regulären ausdruck überein.',
          name:
            'Der wert stimmte nicht mit dem genannten regulären ausdruck überein.',
          _pattern: 'pattern',
        },
      },
      number: {
        base:
          'Der wert ist keine zahl oder konnte nicht in eine zahl umgewandelt werden.',
        integer: 'Die zahl ist keine gültige ganzzahl.',
        less: 'Die anzahl liegt unter oder gleich dem limit.',
        greater: 'Die anzahl ist über oder gleich dem limit.',
        max: 'Die anzahl ist überschritten als das limit.',
        min: 'Die anzahl liegt unter dem grenzwert.',
        negative: 'Die zahl war positiv.',
        positive: 'Die Zahl war negativ.',
        _number: 'number',
      },
      date: {
        base:
          'Der wert ist entweder kein Datum oder kann nicht aus einer Zeichenfolge oder einer zahl in ein datum umgewandelt werden.',
        format: 'Das datum stimmt nicht mit dem erforderlichen format überein.',
        greater: 'Das datum ist über dem limit.',
        less: 'Das datum ist unter dem limit.',
        max: 'Das datum ist über oder gleich dem limit.',
        min: 'Das datum liegt unter oder gleich dem limit.',
        _date: 'date',
      },
    },
    country: {
      afghanistan: 'Afghanistan',
      alandIslands: 'Åland Islands',
      albania: 'Albania',
      algeria: 'Algeria',
      americanSamoa: 'American Samoa',
      andorrA: 'AndorrA',
      angola: 'Angola',
      anguilla: 'Anguilla',
      antarctica: 'Antarctica',
      antiguaBarbuda: 'Antigua and Barbuda',
      argentina: 'Argentina',
      armenia: 'Armenia',
      aruba: 'Aruba',
      australia: 'Australia',
      austria: 'Austria',
      azerbaijan: 'Azerbaijan',
      bahamas: 'Bahamas',
      bahrain: 'Bahrain',
      bangladesh: 'Bangladesh',
      barbados: 'Barbados',
      belarus: 'Belarus',
      belgium: 'Belgium',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermuda',
      bhutan: 'Bhutan',
      bolivia: 'Bolivia',
      bosniaHerzegovina: 'Bosnia and Herzegovina',
      botswana: 'Botswana',
      bouvetIsland: 'Bouvet Island',
      brazil: 'Brazil',
      britishIndianOceanTerritory: 'British Indian Ocean Territory',
      bruneiDarussalam: 'Brunei Darussalam',
      bulgaria: 'Bulgaria',
      burkinaFaso: 'Burkina Faso',
      burundi: 'Burundi',
      cambodia: 'Cambodia',
      cameroon: 'Cameroon',
      canada: 'Canada',
      capeVerde: 'Cape Verde',
      caymanIslands: 'Cayman Islands',
      centralAfricanRepublic: 'Central African Republic',
      chad: 'Chad',
      chile: 'Chile',
      china: 'China',
      christmasIsland: 'Christmas Island',
      cocosIslands: 'Cocos (Keeling) Islands',
      colombia: 'Colombia',
      comoros: 'Comoros',
      congo: 'Congo',
      congDemocraticRepublic: 'Congo, Democratic Republic',
      cookIslands: 'Cook Islands',
      costaRica: 'Costa Rica',
      coteIvoire: 'Cote D"Ivoire',
      croatia: 'Croatia',
      cuba: 'Cuba',
      cyprus: 'Cyprus',
      czechRepublic: 'Czech Republic',
      denmark: 'Denmark',
      djibouti: 'Djibouti',
      dominica: 'Dominica',
      dominicanRepublic: 'Dominican Republic',
      ecuador: 'Ecuador',
      egypt: 'Egypt',
      elSalvador: 'El Salvador',
      equatorialGuinea: 'Equatorial Guinea',
      eritrea: 'Eritrea',
      estonia: 'Estonia',
      ethiopia: 'Ethiopia',
      falklandIslands: 'Falkland Islands (Malvinas)',
      faroeIslands: 'Faroe Islands',
      fiji: 'Fiji',
      finland: 'Finland',
      france: 'France',
      frenchGuiana: 'French Guiana',
      frenchPolynesia: 'French Polynesia',
      frenchSouthernTerritories: 'French Southern Territories',
      gabon: 'Gabon',
      gambia: 'Gambia',
      georgia: 'Georgia',
      germany: 'Germany',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      greece: 'Greece',
      greenland: 'Greenland',
      grenada: 'Grenada',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernsey',
      guinea: 'Guinea',
      guineaBissau: 'Guinea-Bissau',
      guyana: 'Guyana',
      haiti: 'Haiti',
      heardIslandMcdonaldIslands: 'Heard Island and Mcdonald Islands',
      holySee: 'Holy See (Vatican City State)',
      honduras: 'Honduras',
      hongKong: 'Hong Kong',
      hungary: 'Hungary',
      iceland: 'Iceland',
      india: 'India',
      indonesia: 'Indonesia',
      iran: 'Iran',
      iraq: 'Iraq',
      ireland: 'Ireland',
      isleofMan: 'Isle of Man',
      israel: 'Israel',
      italy: 'Italy',
      jamaica: 'Jamaica',
      japan: 'Japan',
      jersey: 'Jersey',
      jordan: 'Jordan',
      kazakhstan: 'Kazakhstan',
      kenya: 'Kenya',
      kiribati: 'Kiribati',
      koreaNorth: 'Korea (North)',
      koreaSouth: 'Korea (South)',
      kosovo: 'Kosovo',
      kuwait: 'Kuwait',
      kyrgyzstan: 'Kyrgyzstan',
      laos: 'Laos',
      latvia: 'Latvia',
      lebanon: 'Lebanon',
      lesotho: 'Lesotho',
      liberia: 'Liberia',
      libyanArabJamahiriya: 'Libyan Arab Jamahiriya',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Lithuania',
      luxembourg: 'Luxembourg',
      macao: 'Macao',
      macedonia: 'Macedonia',
      madagascar: 'Madagascar',
      malawi: 'Malawi',
      malaysia: 'Malaysia',
      maldives: 'Maldives',
      mali: 'Mali',
      malta: 'Malta',
      marshallIslands: 'Marshall Islands',
      martinique: 'Martinique',
      mauritania: 'Mauritania',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      mexico: 'Mexico',
      micronesia: 'Micronesia',
      moldova: 'Moldova',
      monaco: 'Monaco',
      mongolia: 'Mongolia',
      montserrat: 'Montserrat',
      morocco: 'Morocco',
      mozambique: 'Mozambique',
      myanmar: 'Myanmar',
      namibia: 'Namibia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      netherlands: 'Netherlands',
      netherlandsAntilles: 'Netherlands Antilles',
      newCaledonia: 'New Caledonia',
      newZealand: 'New Zealand',
      nicaragua: 'Nicaragua',
      niger: 'Niger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      norfolkIsland: 'Norfolk Island',
      northernMarianaIslands: 'Northern Mariana Islands',
      norway: 'Norway',
      oman: 'Oman',
      pakistan: 'Pakistan',
      palau: 'Palau',
      palestinian: 'Palestinian Territory, Occupied',
      panama: 'Panama',
      papua: 'Papua New Guinea',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Philippines',
      pitcairn: 'Pitcairn',
      poland: 'Poland',
      portugal: 'Portugal',
      puertoRico: 'Puerto Rico',
      qatar: 'Qatar',
      reunion: 'Reunion',
      romania: 'Romania',
      russianFederation: 'Russian Federation',
      rwanda: 'Rwanda',
      saintHelena: 'Saint Helena',
      saintKittsNevis: 'Saint Kitts and Nevis',
      saintLucia: 'Saint Lucia',
      saintPierreMiquelon: 'Saint Pierre and Miquelon',
      SainVincentGrenadines: 'Saint Vincent and the Grenadines',
      Samoa: 'Samoa',
      sanMarino: 'San Marino',
      saoTomePrincipe: 'Sao Tome and Principe',
      saudiArabia: 'Saudi Arabia',
      senegal: 'Senegal',
      serbia: 'Serbia',
      montenegro: 'Montenegro',
      seychelles: 'Seychelles',
      sierraLeone: 'Sierra Leone',
      singapore: 'Singapore',
      slovakia: 'Slovakia',
      slovenia: 'Slovenia',
      solomonIslands: 'Solomon Islands',
      somalia: 'Somalia',
      southAfrica: 'South Africa',
      southGeorgiaSouthSandwichIslands:
        'South Georgia and the South Sandwich Islands',
      spain: 'Spain',
      sriLanka: 'Sri Lanka',
      sudan: 'Sudan',
      suriname: 'Suriname',
      svalbardJanMayen: 'Svalbard and Jan Mayen',
      swaziland: 'Swaziland',
      sweden: 'Sweden',
      switzerland: 'Switzerland',
      syrianArabRepublic: 'Syrian Arab Republic',
      taiwanProvinceChina: 'Taiwan, Province of China',
      tajikistan: 'Tajikistan',
      tanzania: 'Tanzania',
      thailand: 'Thailand',
      timorLeste: 'Timor-Leste',
      togo: 'Togo',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidadandTobago: 'Trinidad and Tobago',
      tunisia: 'Tunisia',
      turkey: 'Turkey',
      turkmenistan: 'Turkmenistan',
      turksandCaicosIslands: 'Turks and Caicos Islands',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ukraine',
      unitedArabEmirates: 'United Arab Emirates',
      unitedKingdom: 'United Kingdom',
      unitedStates: 'United States',
      unitedStatesMinorOutlyingIslands: 'United States Minor Outlying Islands',
      uruguay: 'Uruguay',
      uzbekistan: 'Uzbekistan',
      Vanuatu: 'Vanuatu',
      Venezuela: 'Venezuela',
      vieNam: 'Viet Nam',
      virginIslandsBritish: 'Virgin Islands, British',
      virginIslands: 'Virgin Islands, U.S.',
      wallisFutuna: 'Wallis and Futuna',
      westernSahara: 'Western Sahara',
      yemen: 'Yemen',
      zambia: 'Zambia',
      zimbabwe: 'Zimbabwe',
    },
  },
};

export default dictionary;
