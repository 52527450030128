const dictionary = {
  localizations: {
    buttons: {
      uploadFile: 'Dosya Yükle',
      approve: 'Onayla',
      adRequest: 'Reklam Talebi',
      createAdRequest: 'Reklam Talebi Oluştur',
      login: 'Giriş Yap',
      rememberMe: 'Beni Hatırla',
      save: 'Kaydet',
      clear: 'Temizle',
      edit: 'Düzenle',
      update: 'Güncelle',
      delete: 'Sil',
      cancel: 'İptal Et',
      yes: 'Evet',
      no: 'Hayır',
      send: 'Gönder',
      createNewRelation: 'Yeni İlişki Kur',
      makePlan: 'Planla',
      selectAll: 'Hepsini Seç',
      true: 'Doğru',
      false: 'Yanlış',
      makePeriodicPlan: 'Periyodik Planlama',
      makeSpecialPlan: 'Özel Planlama',
      filter: 'Ara',
      detail: 'Detay',
      hourBasedRelease: 'Saat Bazlı Tanımlama',
      minuteBasedRelease: 'Dakika Bazlı Tanımlama',
      songBasedRelease: 'Şarkı Bazlı Tanımlama',
      logout: 'Çıkış',
      hourBased: 'Saat Bazlı',
      minuteBased: 'Dakika Bazlı',
      songBased: 'Şarkı Bazlı',
      home: 'Anasayfa',
      dashboard: 'Panel',
      dashboardPartner: 'Yayın Akışı',
      userManagement: 'Kullanıcı Yönetimi',
      createUser: 'Kullanıcı Oluştur',
      users: 'Kullanıcılar',
      firms: 'Firmalar',
      sectorOfFirm: 'Firma Sektörü',
      branches: 'Şubeler',
      statistic: 'İstatistik',
      songs: 'Şarkılar',
      fieldsForSong: 'Şarkı Alanları',
      songCategory: 'Şarkı Kategorisi',
      artistName: 'Şarkıcı',
      productionCompany: 'Yapımcı Şirket',
      legalTracking: 'Yasal Takip',
      melodist: 'Besteci',
      writer: 'Söz Yazarı',
      album: 'Albüm',
      songPool: 'Şarkı Havuzu',
      songPoolPartnerRelation: 'Havuz-Firma İlişkisi',
      addNewSong: 'Yeni Şarkı Ekle',
      allSongs: 'Tüm Şarkılar',
      addSongAndViewPlaylist: 'Şarkı Görüntüle ve Düzenle',
      playlist: 'Çalma Listesi',
      createPlaylist: 'Çalma Listesi Oluştur',
      advertisement: 'Reklam',
      createAdvertisementSet: 'Reklam Seti Oluştur',
      advertisementPool: 'Reklam Havuzu',
      standardAdvertisement: 'Standart Reklam',
      interAdvertisement: 'Ara Reklam',
      specialAdvertisement: 'Özel Reklam',
      soundTypes: 'Ses Türleri',
      createSoundType: 'Ses Türü Yayınla',
      onOff: 'Çevrimiçi/ÇevrimDışı',
      addPlaylistSong: 'Playliste Şarkı Ekle',
      add: 'Ekle',
      addChosenSongsToPlaylist: 'Seçilen Şarkıları Playliste Ekle',
      createPlaylistPublish: 'Playlist Yayınla',
      confirm: 'Onayla',
      help: 'Yardım',
      iUnderstand: 'Anladım !',
      release: 'Yayınla',
      unRelease: 'Yayından Geri Al',
      timeline: ' Yayın Akışı',
      sounds: ' Ses Türleri',
      advertisements: ' Reklamlar',
      periodicAdvertisements: 'Periyodik Reklamlar',
      periodicAdvertisementsRelease: 'Periyodik Reklam Yayınla',
      specialAdvertisements: 'Özel Reklamlar',
      cancelOption: 'Seçileni İptal Et',
      seeBranches: 'Şubeleri Gör',
      addToSongPlaylist: 'Playlistine Şarkı Ekle',
      createPlaylistName: 'Playlist İsmi Oluştur',
      createPlaylists: 'Playlist Yayınla',
      createAdsSet: 'Reklam Seti',
      advertisementPlanning: 'Reklam Planla',
      standardAdsPublish: 'Standart Reklam Yayınla',
      interAdsPublish: 'Ara Reklam Yayınla',
      specialAdsPublish: 'Özel Reklam Yayınla',
      playlists: 'Yayın Akışı',
      publishSound: 'Jingle Yayınla',
      poolSound: 'Jingle,Teaser,Anons Havuzu',
      addJingle: 'Yeni Jingle Ekle',
      _button: 'button',
    },
    columns: {
      createdAt: 'Oluşturulma Tarihi',
      updatedAt: 'Güncellenme Tarihi',
      woman: 'Kadın',
      man: 'Erkek',
      other: 'Diğer',
      branch: 'Şube',
      partner: 'Firma',
      guest: 'Misafir',
      manager: 'Yönetici',
      admin: 'Admin',
      inter: 'Ara',
      standard: 'Standart',
      special: 'Özel',
      advertisementName: 'Reklam Adı',
      time: 'Süre',
      status: 'Durum',
      planned: 'Planlanmış',
      unPlanned: 'Planlanmamış',
      released: 'Tanımlanmış',
      notReleased: 'Tanımlanmamış',
      isReleased: 'Yayında',
      totalTime: 'Toplam Süre',
      branches: 'Şubeler',
      type: 'Tür',
      name: 'İsim',
      duration: 'Süre',
      soundTypes: 'Ses Türü',
      jingle: 'Jingle',
      jinglePools: 'Jingle Havuzu',
      jinglePublish: 'Jingle Yayınla',
      announce: 'Anons',
      teaser: 'Tanıtım',
      displayTime: 'Yayın Süresi',
      true: 'Doğru',
      false: 'Yanlış',
      hour: 'Saat',
      song: 'Şarkı',
      minute: 'Dakika',
      soundTypesName: 'Ses Türü Adı',
      playlist: 'Çalma Listesi',
      selectedDays: 'Seçilen Günler',
      startHour: 'Başlangıç Saati',
      endHour: 'Bitiş Saati',
      date: 'Date',
      startDate: 'Başlangıç Tarihi',
      endDate: 'Bitiş Tarihi',
      onlineCount: 'Çevrimiçi Sayısı',
      offlineCount: 'Çevrimdışı Sayısı',
      partnerBranch: 'Şubeler',
      lastOnline: 'Son Çevrimiçi',
      lastOffline: 'Son Çevrimdışı',
      version: 'Versiyon',
      createdBy: 'Oluşturan',
      partners: 'Firmalar',
      jinglePoolName: 'Jingle Havuzu Adı',
      jingleName: 'Jingle Adı',
      _columns: 'columns',
    },
    labels: {
      male: 'Erkek',
      female: 'Kadın',
      forgotMyPassword: 'Şifremi Unuttum',
      forgotPasswordMessage:
        'Şifrenizi unuttuysanız buradan yenileyebilirsiniz.',
      partnerBranch: 'Şube',
      advertisementType: 'Reklam Tipi',
      isCutSong: 'Şarkı Kesilsin mi?',
      advertisementSet: 'Reklam Seti',
      startDate: 'Başlangıç Tarihi',
      startDateWithTime: 'Başlangıç Tarihi - Saati',
      endDate: 'Bitiş Tarihi',
      endDateWithTime: 'Bitiş Tarihi - Saati',
      time: 'Zaman',
      true: 'Doğru',
      false: 'Yanlış',
      advertisementName: 'Reklam Adı',
      partner: 'Firma',
      date: 'Tarih',
      start: 'Başlangıç',
      end: 'Bitiş',
      duration: 'Süre',
      dailyRepeat: 'Günlük Tekrar',
      totalRepeat: 'Toplam Tekrar',
      dailyTotalDuration: 'Günlük Toplam Süre',
      totalDuration: 'Toplam Süre',
      broadcastTimes: 'Yayın Saatleri',
      hours: 'Saat',
      minutes: 'Dakika',
      seconds: 'Saniye',
      name: 'İsim',
      soundType: 'Ses Türü',
      advertisemet: 'Reklam',
      repeatNumber: 'Tekrar Sayısı',
      period: 'Periyot',
      onHowManyMinutes: 'Kaç dakikada bir',
      onHowManyHours: 'Kaç saatte bir',
      onHowManySongs: 'Kaç şarkıda bir',
      album: 'Albüm',
      artist: 'Sanatçı',
      melodist: 'Besteci',
      writer: 'Söz Yazarı',
      productionCompany: 'Yapım Şirketi',
      playlistKind: 'Playlist Türü',
      popularity: 'Şarkı Kalitesi',
      soundName: 'Ses Adı',
      repeat: 'Tekrar',
      periodParameter: 'Periyot Parametresi',
      selectPlaylistSongs:
        'Şarkılarını göstermek için bir çalma listesi seçin:',
      newSelectSongWhichLsit:
        'Yeni seçilen şarkıları hangi şarkı listesine eklemek istiyorsunuz?',
      playlist: 'Çalma Listesi',
      jan: 'Ocak',
      feb: 'Şubat',
      march: 'Mart',
      apr: 'Nisan',
      may: 'Mayıs',
      june: 'Haziran',
      jul: 'Temmuz',
      aug: 'Ağustos',
      sep: 'Eylük',
      oct: 'Ekim',
      nov: 'Kasım',
      dec: 'Aralık',
      startTime: 'Başlangıç Saati',
      endTime: 'Bitiş Tarihi',
      allBranchesSelected: 'Tüm Şubeler Seçildi',
      signboard: 'Tabela Adı',
      jingle: 'Jingle',
      announce: 'Anons',
      teaser: 'Tanıtım',
      standard: 'Standart',
      inter: 'Ara',
      special: 'Özel',
      songs: 'Şarkılar',
      advertisements: 'Reklamlar',
      soundTypes: 'Ses Türleri',
      totalPublicationsCount: 'Toplam Yayınlanma',
      MONDAY: 'Pazartesi',
      TUESDAY: 'Salı',
      WEDNESDAY: 'Çarşamba',
      THURSDAY: 'Perşembe',
      FRIDAY: 'Cuma',
      SATURDAY: 'Cumartesi',
      SUNDAY: 'Pazar',
      days: 'Günler',
      allDays: 'Tüm Günler',
      weekend: 'Haftasonu',
      weekdays: 'Haftaiçi',
      startHour: 'Başlangıç Saati',
      endHour: 'Bitiş Saati',
      song: 'Şarkı',
      songPool: 'Şarkı Havuzu',
      wasDefined: 'tanımlaması yapıldı',
      affectedBranches: 'Etkilenen Şubeler',
      catagory: 'Kategori',
      dateTooltip: 'Tarih seçerek tanımlanan şarkıları görebilirsiniz.',
      inHowManySongs: 'Kaç Şarkıda Bir',
      _label: 'label',
    },
    placeholders: {
      multipleLinksTypo: 'Çoklu linkleri yeni satırla ayırınız.',
      password: 'Şifre',
      username: 'Kullanıcı Adı',
      chooserole: 'Yetki Seçiniz',
      search: 'Arama yapılacak kelime...',
      selectValue: 'Bir değer seçiniz',
      selectPartner: 'Partner Seçiniz.',
      selectBranch: 'Şube seçiniz',
      selectPlaylist: 'Çalma listesi seçiniz',
      selectAdvertisementSet: 'Reklam seti seçiniz',
      selectTime: 'Zaman seçiniz',
      selectDate: 'Tarih seçiniz',
      days: 'Gün seçiniz',
      selectJingle: 'Jinge Havuzu Seçiniz',
      _placeholder: 'placeholder',
    },
    messages: {
      requiredUsername: 'Kullanıcı adınızı giriniz!',
      requiredPassword: 'Şifrenizi giriniz!',
      successMessage: 'Kayıt başarılı.',
      successUserCreate: 'Kullanıcı başarıyla oluşturuldu.',
      notSuccessUserCreate: 'Kullanıcı oluşturulurken hata oluştu.',
      firstname: 'Kullanıcı adını giriniz!',
      lastname: 'Kullanıcı soyadını giriniz!',
      email: 'Email adresini giriniz!',
      password: 'Şifre giriniz!',
      confirmpassword: 'Şifreyi tekrar giriniz!',
      notvalidemail: 'Geçerli bir Eposta adresi giriniz!',
      recordNotFound: 'İlgili kayıt bulunamadı!',
      somethingwrong: 'Birşeyler ters gitti!',
      successUpdate: 'Güncelleme başarılı.',
      somethingBad: 'Birşeyler kötü gitti!',
      sure: 'Emin misin?',
      deleteSure: 'Playlist silinecek emin misin?',
      chooseUser: 'Lütfen kullanıcı seçiniz!',
      notFoundContent: 'Sorgunuzla ilgili veri yok',
      notAuthorized: 'Maalesef, bu sayfaya erişim izniniz yok.',
      serverWrong: 'Sunucuda hata oldu',
      notExist: 'Maalesef, ziyaret ettiğiniz sayfa mevcut değil.',
      successful: 'Başarılı',
      selectProductionCompany: 'Lütfen yapım şirketi seçiniz.',
      selectArtist: 'Lütfen sanatçı seçiniz.',
      selectAlbum: 'Lütfen albüm seçiniz.',
      selectWriter: 'Lütfen söz yazarı seçiniz.',
      selectMelodist: 'Lütfen besteci seçiniz.',
      selectSongPool: 'Lütfen şarkı havuzu seçiniz.',
      selectSongCategory: 'Lütfen şarkı kategorisi seçiniz.',
      selectPartner: 'Lütfen firma seçiniz.',
      clickUpload: 'Yüklenecek Şarkı Seçiniz',
      clickUploadImage: 'Yüklenecek Fotoğrafı Seçiniz',
      invalideFile: 'Geçersiz dosya tipi',
      fileSmaller: 'Dosya 100 MB tan küçük olmalıdır.',
      relationSongPartner: 'İlişki tablosunu göstermek için partner seçiniz:',
      selectPopularity: 'Lütfen alt katagori seçiniz.',
      forgotMessage:
        'Şifreniz mail adresinize gönderilmiştir.Lütfen kontrol ediniz.',
      selectPlaylistKind: 'Lütfen playlist türü seçiniz',
      primaryPhone: 'Telefon numarası giriniz!',
      secondaryPhone: 'Cep telefon numarası giriniz!',
      selectPartnerSector: 'Firma sektörünü seçiniz',
      notFountContent: 'İçerik bulunamadı.',
      notaddSong: 'Seçili şarkı havuzuna henüz şarkı eklenmemiş.',
      fileSize: 'Dosya boyutu 15 sn den büyük olamaz.',
      uSelectedDate: 'Seçilen tarih',
      datesGapCanNotBeGt90Days:
        'Başlangıç ve bitiş tarihleri arasındaki fark 90 günden daha büyük olamaz!',
      clickUploadJingle: 'Yüklenecek Jingle Seçiniz',
      appHasNewVersion: 'Uygulama Yeni Bir Versiyona Sahip!',
      _message: 'message',
    },
    header: {
      home: 'Anasayfa',
      dashboard: 'Panel',
      usermanagement: 'Kullanıcı Yönetimi',
      createuser: 'Kullanıcı Oluştur',
      partner: 'Firmalar',
      partnerBranch: 'Şubeler',
      statistic: 'İstatistik',
      onlineoffline: 'Online/Offline',
      playlistmanager: 'Playlist Yönetimi',
      adsmanager: 'Reklam Yönetimi',
      standartads: 'Standart Reklam',
      interads: 'Ara Reklam',
      specialads: 'Özel Reklam',
      kindmanager: 'Türlerin Yönetimi',
      songPoolPartnerRelation: 'Şarkı Havuzu ve Firma İlişkisi',
      advertisementPool: 'Reklam Havuzu',
      songUpload: 'Şarkı Yükle',
      soundTypes: 'Ses Türleri',
      partnerSector: 'Sektör',
      idendifiedSoundKinds: 'Yayınlanan Jingle',
      playlist: 'Playlist Yayınla',
      onlineOfflineInfo: 'Online / Offline Bilgisi',
      online: 'Çevrimiçi',
      offline: 'Çevrimdışı',
      totalPartnerBranch: 'Tüm Şubelerin Durumu',
      totalBranch: 'Şube Durumu',
      releasedJingle: 'Jingle Yayınla',
      _header: 'header',
    },
    form: {
      createuser: 'Kullanıcı Oluştur',
      username: 'Kullanıcı Adı',
      name: 'Ad',
      surname: 'Soyad',
      email: 'Email',
      password: 'Şifre',
      confirmpassword: 'Şifreyi Onayla',
      description: 'Açıklama',
      role: 'Yetki',
      _form: 'form',
    },
    titles: {
      voiceoverPerformer: 'Seslendirmen',
      links: 'Linkler',
      text: 'Metin',
      title: 'Başlık',
      adRequest: 'Reklam Talebi',
      username: 'Kullanıcı Adı',
      name: 'Ad',
      surname: 'Soyad',
      email: 'Email',
      role: 'Yetki',
      partnerName: 'Firma Adı',
      partnerBranchName: 'Şube Adı',
      action: 'İşlem',
      users: 'Kullanıcılar',
      userDetail: 'Kullanıcı Detayı',
      description: 'Açıklama',
      newPartner: 'Yeni Firma Oluştur',
      newPartnerBranch: 'Yeni Şube Oluştur',
      songCategory: 'Şarkı Kategorisi',
      songPoolCategory: 'Şarkı Havuzu Kategorisi',
      categoryName: 'Kategori Adı',
      time: 'Süre',
      totalFiles: 'Dosya Sayısı',
      newCategoryAdd: 'Yeni Kategori Oluştur',
      newSongPoolAdd: 'Yeni Şarkı Havuzu Oluştur',
      artistFirstName: 'Sanatçı Adı',
      artistLastName: 'Sanatçı Soyadı',
      gender: 'Cinsiyet',
      Artist: 'Sanatçı',
      newArtist: 'Yeni Sanatçı',
      productionCompanyName: 'Yapım Şirketi Adı',
      productionCompany: 'Yapım Şirketi',
      newproductionCompany: 'Yeni Yapım Şirketi',
      advertisementName: 'Reklam Seti Adı',
      advertisementType: 'Reklam Tipi',
      advertisementItems: 'Zamanı',
      advertisementSet: 'Reklam Seti',
      newAdvertisementSet: 'Yeni Reklam Seti',
      legalPercuitName: 'Yasal Takip Adı',
      legalPercuit: 'Yasal Takip',
      newlegalPercuit: 'Yeni Yasal Takip',
      choose: 'Seçiniz',
      userInfo: 'Kullanıcı Bilgileri',
      country: 'Ülke',
      productionDate: 'Üretim Tarihi',
      album: 'Albüm',
      songName: 'Şarkı Adı',
      songWriter: 'Söz Yazarı',
      composer: 'Besteci',
      subCategory: 'Alt Kategori',
      melodists: 'Besteci',
      newMelodist: 'Yeni Besteci',
      albums: 'Albümler',
      newAlbum: 'Yeni Albüm',
      writers: 'Söz Yazarları',
      newWriter: 'Yeni Söz Yazarı',
      popularity: 'Alt Kategori',
      songPool: 'Şarkı Havuzu',
      song: 'Şarkı',
      file: 'Dosya',
      startDate: 'Başlangıç Tarihi',
      endDate: 'Bitiş Tarihi',
      newAdvertisement: 'Yeni Reklam Oluştur',
      photo: 'Fotoğraf',
      planAdvertisement: 'Reklam Planlama',
      standardAds: 'Standart Reklam',
      interAds: 'Ara Reklam',
      logOut: 'Çıkış Yap',
      newPlaylist: 'Yeni Playlist İsmi',
      playlist: 'Playlist İsmi',
      backHome: 'Anasayfaya Dön',
      primaryPhone: 'Telefon',
      secondaryPhone: 'GSM',
      partnerSector: 'Firma Sektör',
      sectorName: 'Sektör Adı',
      newSector: 'Yeni Sektör',
      sectorCategory: 'Sektör Kategorisi',
      newSectorCategory: 'Yeni Sektör Kategorisi',
      songPoolName: 'Şarkı Havuzu Adı',
      createNewRelation: 'Yeni İlişki Kur',
      createSoundType: 'Ses Türü Oluştur',
      releaseSound: 'Ses Türü Yayınlama',
      addSongtoPlaylist: 'Playliste Şarkı Ekle',
      activeBranchCount: 'Aktif Şube Sayısı',
      totalPartnerCount: 'Toplam Firma Sayısı',
      totalBranchCount: 'Toplam Şube Sayısı',
      planningPlaylistCount: 'Planlanan Playlist Sayısı',
      planningSpeciesCount: 'Planlanan Tür Sayısı',
      planningTeaserCount: 'Planlanan Teaser Sayısı',
      planningAdvertisementCount: 'Planlanan Reklam Sayısı',
      totalSongCount: 'Toplam Şarkı Sayısı',
      totalCategoryCount: 'Toplam Şarkı Kategori Sayısı',
      statisticSummary: 'Bu Ayın Özet Verileri',
      advertisementStatistic: ' Reklam İstatistikleri',
      soundTypeStatistic: 'Ses Türlerinin İstatistikleri',
      songStatistic: 'Şarkı İstatistikleri',
      mountlyAdvertisementStatistic: 'Aylara Göre Reklam İstatistikleri',
      adsvertisementPercent: 'Reklamların Yüzdesel Görünümü',
      standard: 'Standart',
      inter: 'Ara',
      special: 'Özel',
      teaserMountlyStatistic: 'Tanıtımların Aylara Göre İstatistikleri',
      jingleMounlyStatistic: 'Jingleların Aylara Göre İstatistikler',
      announceMontlyStatictic: 'Anonsların Aylara Göre İstatistikleri',
      month: 'Ayı',
      most: 'En Çok',
      least: 'En Az',
      playTenSong: 'Çalan 10 Şarkı',
      beforeMonth: 'Önceki Ay',
      afterMonth: 'Sonraki Ay',
      mostThreeSong: 'En çok çalan ilk 3 şarkı:',
      advertisementReleasedCount: 'Reklamların Yayınlanma Sayıları:',
      songBaseReleasedCount: 'Ses Türlerinin Yayınlanma Sayıları:',
      totalReleased: 'Toplam Yayınlama:',
      timelineTable: 'Timeline Gün Detay Tablosu',
      verticalTimeline: 'Timeline Detay',
      tr: 'Türkçe',
      en: 'İngilizce',
      de: 'Almanca',
      welcome: ' Havalı Ajans Player Yönetim Paneli',
      howToReleaseSoundType: 'Nasıl ses türü yayınlanır?',
      songDetail: 'Şarkı Detayı',
      standardAdvertisement: 'Standart Reklam',
      advertisementDetail: 'Reklam Detayı',
      soundDetail: 'Ses Detayı',
      timeline: 'Takvim',
      specialAdvertisement: 'Özel Reklam',
      announce: 'Anons',
      jingle: 'Jingle',
      teaser: 'Tanıtım',
      interAdvertisement: 'Ara Reklam',
      publishList: 'Yayın Listesi',
      standartStartDate: 'Başlangıç',
      standartEndDate: 'Bitiş',
      advertisement: 'Reklam',
      updatePlaylist: 'Çalma Listesini Güncelle',
      newJingle: 'Yeni Jingle',
      jingleCount: 'Jingle Sayısı',
      newJinglePoolName: 'Yeni Jingle Havuzu',
      _titles: 'titles',
    },
    warnings: {
      required: 'Bu alan gereklidir!',
      email: 'Email adresini giriniz!',
      string: 'Bu alan metin olmalıdır!',
      min: 'Değer minimum sınırdan düşük!',
      max: 'Değer maksimum sınırdan yüksek!',
      noData: 'Bilgi yok',
      noAdvertisement: 'Reklam Yok',
      notYetAddedSongPoolToChosenPlaylist:
        'Seçtiğiniz firmanın bir şarkı havuzu henüz oluşturulmamış!',
      allBranchesSelected: 'Tüm Şubeler Seçildi!',
      chosenPlaylistAffectAllBranches:
        'Seçeceğiniz müzik çalar, tüm şubeleri etkileyecektir!',
      startMustBeSmallerThanEnd:
        'Başlangıç değeri, bitiş değerinden büyük olamaz!',
      chooseOneValue: 'En az iki değerden birini seçmelisiniz!',
      standartAds:
        'Belirtilen tarih aralıklarında Standart Reklam bulunamamaktadır.',
      periodicAds:
        'Belirtilen tarih aralıklarında Periyodik Reklam bulunamamaktadır.',
      interAds: 'Belirtilen tarih aralıklarında Ara Reklam bulunamamaktadır.',
      specialAds:
        'Belirtilen tarih aralıklarında Özel Reklam bulunamamaktadır.',
      noApp: 'Kurulum Yapılmadı.',
      chooseSongPool: 'Lütfen bir şarkı havuzu seçiniz!',
      _warning: 'warning',
    },
    tooltips: {
      hourBased: 'X saatte bir çal',
      minuteBased: 'X dakikada bir çal',
      songBased: 'X şarkıda bir çal',
    },
    errors: {
      audioTooLong: 'Reklam süresi 5 dakikadan daha fazla olamaz!',
      requestFailed: 'Üzgünüz, istek başarısız!',
      pleaseCheckInputs: 'Lütfen giriş değerlerini kontrol edin!',
      uniqueConstraint: 'Bu kayıt daha önce kaydedilmiş.',
      checkUsernamePassword: 'Lütfen kullancı adı ve şifreyi kontrol ediniz!',
      unsupportedMimeType: 'Lütfen yüklediğiniz dosya tipini kontrol ediniz.',
      timelineConflict: 'Zaman Çizelgesinde Çakışma Var',
      unSuccessful: 'Başarısız',

      _error: 'error',
      any: {
        required: 'Gerekli bir değer yok.',
        _any: 'any',
      },
      string: {
        base: 'Girilen değer normal ifadeyle eşleşmedi.',
        empty: 'Boş değer',
        min: 'Girilen değer beklenenden daha kısa.',
        max: 'Girilen değer beklenenden daha uzun.',
        email: 'Girilen değer geçerli bir e-posta değil.',
        lowercase: 'Girilen değer küçük harfli değildir.',
        uppercase: 'Girilen değerin tamamı büyük harfli değildir.',
        length: 'Girilen değer beklenen uzunlukta değildir.',
        _string: 'srting',
        pattern: {
          base: 'Girilen değer normal ifadeyle eşleşmedi.',
          name: 'Girilen değer adlandırılan normal ifadeyle eşleşmedi.',
          _pattern: 'pattern',
        },
      },
      number: {
        base: 'Değer bir sayı değil veya bir sayıya dönüştürülemedi.',
        integer: 'Sayı geçerli bir tamsayı değil.',
        less: 'Sayı sınırdan az veya eşit.',
        greater: 'Sayı sınırdan büyük veya eşit',
        max: 'Girilen değer beklenenden daha uzun.',
        min: 'Girilen değer beklenenden daha kısa.',
        negative: 'Sayı negatif.',
        positive: 'Sayı pozitif',
        _number: 'number',
      },
      date: {
        base:
          'Değer bir tarih değil veya bir dizeden veya sayıdan bir tarihe aktarılamadı.',
        format: 'Tarih, gerekli biçimle eşleşmiyor.',
        greater: 'Tarih sınırı aşıyor',
        less: 'Tarih sınırın altında',
        max: 'Tarih sınırın üstünde veya buna eşit.',
        min: 'Tarih sınırın altında veya buna eşit.',
        _date: 'date',
      },
    },
    page: {
      home: 'Anasayfa',
    },
    country: {
      afghanistan: 'Afganistan',
      germany: 'Almanya',
      americanSamoa: 'Amerikan Samoa',
      aruba: 'Aruba,Hollanda',
      australia: 'Avusturalya',
      austria: 'Avusturya',
      andorrA: 'Andorra',
      angola: 'Angola',
      anguilla: 'Anguilla,İngiltere',
      antarctica: 'Antartika',
      antiguaBarbuda: 'Antigua ve Barbuda',
      argentina: 'Arjantin',
      albania: 'Arnavutluk',
      azerbaijan: 'Azerbyancan',
      bahamas: 'Bahama Adaları',
      bahrain: 'Bahreyn',
      bangladesh: 'Bangladeş',
      barbados: 'Barbados',
      westernSahara: 'Batı Sahra',
      belarus: 'Belarus',
      belgium: 'Belçika',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermuda, İngiltere',
      bhutan: 'Bhutan',
      bolivia: 'Bolivya',
      bosniaHerzegovina: 'Bosna Hersek',
      botswana: 'Botswana',
      bouvetIsland: 'Bouvet Adası',
      brazil: 'Brezilya',
      britishIndianOceanTerritory: 'Britanya Hint Okyanusu Toprakları',
      bruneiDarussalam: 'Brunei Sultanlığı',
      bulgaria: 'Bulgaristan',
      burkinaFaso: 'Burkina Faso',
      burundi: 'Burundi',
      capeVerde: 'Cape Verde',
      caymanIslands: 'Cayman Adaları',
      gibraltar: 'Cebelitarık',
      algeria: 'Cezayir',
      christmasIsland: 'Christmas Adası',
      djibouti: 'Cibuti',
      chad: 'Çad',
      china: 'Çin',
      centralAfricanRepublic: 'Orta Afrika Cumhuriyeti',
      cocosIslands: 'Cocos (Keeling) Adaları',
      comoros: 'Comoros',
      czechRepublic: 'Çek Cumhuriyeti',
      denmark: 'Danimarka',
      dominica: 'Dominika',
      dominicanRepublic: 'Dominik Cumhuriyeti',
      indonesia: 'Endonezya',
      ecuador: 'Ekvador',
      armenia: 'Ermenistan',
      elSalvador: 'El Salvador',
      equatorialGuinea: 'Ekvator Ginesi',
      eritrea: 'Eritre',
      estonia: 'Estonya',
      ethiopia: 'Etiyopya',
      falklandIslands: 'Falkland Adaları (Malvinas)',
      faroeIslands: 'Faroe Adaları',
      morocco: 'Fas',
      fiji: 'Fiji',
      coteIvoire: 'Fildişi Sahili',
      finland: 'Finlandiya',
      france: 'Fransa',
      frenchGuiana: 'Fransız Guyanası',
      frenchPolynesia: 'Fransız Polinezyası',
      frenchSouthernTerritories: 'Fransız Güney Toprakları',
      gabon: 'Gabon',
      gambia: 'Gambiya',
      georgia: 'Gürcistan',
      ghana: 'Gana',
      greenland: 'Grönland',
      grenada: 'Grenada',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernsey',
      guinea: 'Gine',
      guineaBissau: 'Guinea-Bissau',
      guyana: 'Guyana',
      haiti: 'Haiti',
      heardIslandMcdonaldIslands: 'Heard Adaları and Mcdonald Adaları',
      croatia: 'Hırvatistan',
      holySee: 'Holy See (Vatikan Şehir Devleti)',
      honduras: 'Honduras',
      hongKong: 'Hong Kong',
      netherlands: 'Hollanda',
      netherlandsAntilles: 'Hollanda Antilleri',
      india: 'Hindistan',
      iraq: 'Irak',
      ireland: 'İrlanda',
      sweden: 'İsveç',
      switzerland: 'İsviçre',
      iceland: 'İzlanda',
      iran: 'İran',
      israel: 'İsrail',
      italy: 'İtalya',
      jamaica: 'Jamaika',
      japan: 'Japonya',
      jersey: 'Jersey',
      jordan: 'Ürüdn',
      kazakhstan: 'Kazakistan',
      kenya: 'Kenya',
      kiribati: 'Kiribati',
      koreaNorth: 'Kuzey Kore',
      koreaSouth: 'Güney Kore',
      kosovo: 'Kosova',
      kuwait: 'Kuveyt',
      kyrgyzstan: 'Kırgızistan',
      cambodia: 'Kamboçya',
      cameroon: 'Kamerun',
      canada: 'Kanada',
      colombia: 'Kolombiya',
      congo: 'Kongo',
      congDemocraticRepublic: 'Kongo, Demokratik Cumhuriyeti',
      costaRica: 'Kosta Rika',
      cuba: 'Küba',
      cyprus: 'Kıbrıs',
      laos: 'Laos',
      latvia: 'Letonya',
      lebanon: 'Lübnan',
      lesotho: 'Lesotho',
      liberia: 'Liberya',
      libyanArabJamahiriya: 'Libya Arap Jamahiriya',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Litvanya',
      luxembourg: 'Lüksemburg',
      hungary: 'Macaristan',
      macao: 'Macao',
      macedonia: 'Makedonya',
      madagascar: 'Madagaskar',
      malawi: 'Malawi',
      malaysia: 'Malezya',
      maldives: 'Maldivler',
      mali: 'Mali',
      malta: 'Malta',
      isleofMan: 'Man Adası',
      marshallIslands: 'Marshall Adaları',
      martinique: 'Martinik',
      mauritania: 'Moritanya',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      mexico: 'Meksika',
      egypt: 'Mısır',
      micronesia: 'Mikronezya',
      moldova: 'Moldova',
      monaco: 'Monako',
      mongolia: 'Moğolistan',
      montserrat: 'Montserrat',
      mozambique: 'Mozambik',
      myanmar: 'Myanmar',
      namibia: 'Namibya',
      nauru: 'Nauru',
      nepal: 'Nepal',
      newCaledonia: 'Yeni Kaledonya',
      newZealand: 'Yeni Zelanda',
      nicaragua: 'Nikaragua',
      niger: 'Nijer',
      nigeria: 'Nijerya',
      niue: 'Niue',
      norfolkIsland: 'Norfolk Adası',
      northernMarianaIslands: 'Kuzey Mariana Adaları',
      norway: 'Norveç',
      oman: 'Umman',
      pakistan: 'Pakistan',
      palau: 'Palau',
      palestinian: 'Filistin',
      panama: 'Panama',
      papua: 'Papua Yeni Gine',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Filipinler',
      pitcairn: 'Pitcairn',
      poland: 'Polonya',
      portugal: 'Portekiz',
      puertoRico: 'Porto Riko',
      qatar: 'Katar',
      reunion: 'Birleşme',
      romania: 'Romanya',
      russianFederation: 'Rusya Federasyonu',
      rwanda: 'Ruanda',
      saintHelena: 'Saint Helena',
      saintKittsNevis: 'Saint Kitts ve Nevis',
      saintLucia: 'Saint Lucia',
      saintPierreMiquelon: 'Saint Pierre ve Miquelon',
      sainVincentGrenadines: 'Saint Vincent ve Grebadinler',
      Samoa: 'Samoa',
      sanMarino: 'San Marino',
      saoTomePrincipe: 'Sao Tome ve Principe',
      saudiArabia: 'Suudi Arabistan',
      senegal: 'Senegal',
      serbia: 'Sırbistan',
      montenegro: 'Karadağ',
      seychelles: 'Seyşeller',
      sierraLeone: 'Sierra Leone',
      singapore: 'Singapur',
      slovakia: 'Slovakya',
      slovenia: 'Slovenya',
      solomonIslands: 'Solomon Adaları',
      somalia: 'Somali',
      southAfrica: 'Güney Afrika',
      southGeorgiaSouthSandwichIslands:
        'Güney Georgia ve Güney Sandwich Adaları',
      spain: 'İspanya',
      sriLanka: 'Sri Lanka',
      sudan: 'Sudan',
      suriname: 'Surinam',
      svalbardJanMayen: 'Svalbard ve Jan Mayen',
      swaziland: 'Svaziland',
      chile: 'Şili',
      syrianArabRepublic: 'Suriye Arap Cumhuriyeti',
      taiwanProvinceChina: 'Tayvan',
      tajikistan: 'Tacikistan',
      tanzania: 'Tanzanya',
      thailand: 'Tayland',
      timorLeste: 'Timor-Leste',
      togo: 'Togo',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidadandTobago: 'Trinidad ve Tobago',
      tunisia: 'Tunus',
      turkey: 'Türkiye',
      turkmenistan: 'Turkmenistan',
      turksandCaicosIslands: 'Turks ve Caicos Adaları',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ukrayne',
      unitedArabEmirates: 'Birleşik Arap Emirlikleri',
      unitedKingdom: 'Birleşik Krallık',
      unitedStates: 'Amerika Birleşik Devleti',
      unitedStatesMinorOutlyingIslands:
        'Amerika Birleşik Devletleri Küçük Dış Adaları',
      uruguay: 'Uruguay',
      uzbekistan: 'Özbekistan',
      vanuatu: 'Vanuatu',
      venezuela: 'Venezuela',
      vienam: 'Viet Nam',
      virginIslandsBritish: 'Virgin Adaları, İngiliz',
      virginIslands: 'Virgin Adaları, U.S.',
      wallisFutuna: 'Wallis ve Futuna',
      yemen: 'Yemen',
      greece: 'Yunanistan',
      zambia: 'Zambiya',
      zimbabwe: 'Zimbabve',
    },
  },
};

export default dictionary;
